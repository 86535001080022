import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Layout from '../../layouts'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Search from '../../components/Search'

const CartRoll = (props) => {
  const [showSearchModal, setShowSearchModal] = useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "cart-categories" } }}
        ) {
          edges {
            node {
              frontmatter {
                bg {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                    }
                  }
                }
                categories{
                  category01{
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                        }
                      }
                    }
                    title
                  }
                  category03{
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                        }
                      }
                    }
                    title
                  }
                  category04{
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                        }
                      }
                    }
                    title
                  }
                  category05{
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                        }
                      }
                    }
                    title
                  }
                  category07{
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                        }
                      }
                    }
                    title
                  }
                  category08{
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 80) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                        }
                      }
                    }
                    title
                  }
                }
              }
            }
          }
        }
        ...LayoutFragment
      }
    `
  )


  function handleSearchClose () {
    setShowSearchModal(false)
    window.scrollTo(0, 0)
  }

  // const { data } = props
  // const { edges: posts } = data.allMarkdownRemark
  const { markdownRemark: post, footerData, navbarData, bannerData } = data

  return (

    <Layout navbarData={navbarData} footerData={footerData} bannerData={bannerData}>
      <div className='imageContainer' style={{ position: 'relative' }}>
        <Img
          fluid={data.allMarkdownRemark.edges[0].node.frontmatter.bg.childImageSharp.fluid}
          style={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'brightness(40%)'
          }}
        />
      </div>
      <section id='karte' className='section'>
        <div className='column is-12 has-text-centered'>
        {showSearchModal ?
            <Search
              closeSearchModal={handleSearchClose}

             />
        : <SearchIconWrapper onClick={(e) => setShowSearchModal(true) }><SearchIcon><div className='btn' /></SearchIcon></SearchIconWrapper>}
        </div>
        <div className='container'>

          <div className='columns is-multiline' style={{ marginTop: '2rem' }}>
            <div className='column is-12 has-text-centered'>
              <div style={{ position: 'relative' }}>
                <h1 className='title is-2 has-text-white-ter' style={{ marginBottom: '3rem' }}>Click & Collect Demo</h1>
                <div className='headline' />
              </div>
            </div>



            <div className='column is-6'>
              <CartPreviewItem>
                <Link to='/products/cloth'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>

                      <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                        {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category01.title}
                      </div>
                      {/*<Img fluid={data.categories.image.childImageSharp.fluid} />*/}
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category01.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />

                  </article>
                </Link>
              </CartPreviewItem>
            </div>

            {/*<div className='column is-6'>
              <CartPreviewItem>
                <Link to='/products/softdrinks'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>

                    <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category02.title}
                    </div>
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category02.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />

                  </article>
                </Link>
              </CartPreviewItem>
            </div>*/}

            <div className='column is-6'>
              <CartPreviewItem>
                <Link to='/products/toys'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>

                    <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category03.title}
                    </div>
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category03.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />

                  </article>
                </Link>
              </CartPreviewItem>
            </div>

            <div className='column is-6'>
              <CartPreviewItem>
                <Link to='/products/wine'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>

                    <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category04.title}
                    </div>
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category04.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />

                  </article>
                </Link>
              </CartPreviewItem>
            </div>

            <div className='column is-6'>
              <CartPreviewItem>
                <Link to='/products/books'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>

                    <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category05.title}
                    </div>
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category05.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />

                  </article>
                </Link>
              </CartPreviewItem>
            </div>

            {/*<div className='column is-6'>
              <CartPreviewItem>
                <Link to='/karte/spirituosen-und-shots'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>

                    <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category06.title}
                    </div>
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category06.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />

                  </article>
                </Link>
              </CartPreviewItem>
            </div>*/}

            <div className='column is-6'>
              <CartPreviewItem>
                <Link to='/products/shoes'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>
                    <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                      {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category07.title}
                    </div>
                    {/*<Img fluid={data.categories.image.childImageSharp.fluid} />*/}
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category07.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />

                  </article>
                </Link>
              </CartPreviewItem>
            </div>

            <div className='column is-6'>
              <CartPreviewItem>
                <Link to='/products/food'>
                  <article style={{ padding: '0', boxShadow: '0 0 10px rgba(0,0,0,.5)', margin: '0 auto', position: 'relative' }}>
                    <div className='title is-size-4 has-text-centered is-uppercase' style={{ position: 'absolute', width: '100%', background: 'rgba(0,0,0,.5)', top: '80px', zIndex: '20', padding: '15px', overflow: 'hidden', color: '#fff' }}>
                      {/*{data.categories.title}*/}
                      {data.allMarkdownRemark.edges[0].node.frontmatter.categories.category08.title}
                    </div>
                    {/*<Img fluid={data.categories.image.childImageSharp.fluid} />*/}
                    <Img
                      fluid={data.allMarkdownRemark.edges[0].node.frontmatter.categories.category08.image.childImageSharp.fluid}
                      style={{ maxHeight: '200px', maxWidth: 'auto' }}
                      imgStyle={{ objectFit: 'cover' }}
                    />
                  </article>
                </Link>
              </CartPreviewItem>
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}


// CartRoll.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array,
//     }),
//   }),
// }

export default CartRoll


// export default () => (
//   <StaticQuery
//     query={graphql`
//       query {
//         image: file(relativePath: { eq: "coffee.jpg" }) {
//           childImageSharp {
//             fluid(quality: 100, maxWidth: 4160) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//         ...LayoutFragment
//     }
//     `}
//     render={(data, count) => (
//       <CartRoll data={data} count={count} />
//     )}
//   />
// )

// image: file(relativePath: { eq: "coffee.jpg" }) {
//   childImageSharp {
//     fluid(quality: 100, maxWidth: 4160) {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
// cocktails: file(relativePath: { eq: "cocktails.jpg" }) {
//   childImageSharp {
//     fluid(quality: 100, maxWidth: 500, maxHeight: 400) {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
// coffee: file(relativePath: { eq: "coffee.jpg" }) {
//   childImageSharp {
//     fluid(quality: 100, maxWidth: 500, maxHeight: 400) {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
// snacks: file(relativePath: { eq: "snacks.jpg" }) {
//   childImageSharp {
//     fluid(quality: 100, maxWidth: 500, maxHeight: 400) {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }
// drinks: file(relativePath: { eq: "drinks.jpg" }) {
//   childImageSharp {
//     fluid(quality: 100, maxWidth: 500, maxHeight: 400) {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }

const CartPreviewItem = styled.div`

  background: white;
  max-height: 200px;
  overflow: hidden;
  // border-radius: 3px;
  // background-color: rgba(255,255,255,.7);
  // box-shadow: 0 0 15px rgba(0,0,0,.6);
  // // padding: 12px;
  // margin: 0 auto;
  // position: relative;
`

const SearchIconWrapper = styled.div`
  position: absolute;
  z-index: 2000;
  top: 15px;
  // right: 80%;
  // left: 80%;
  right: 35px;
  margin-left: auto;
  margin-right: auto;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  cursor: pointer;


`

const SearchIcon = styled.div`
  width: 30px;
  height: 30px;
  background: none;
  border-radius: 50% !important;
  // border: 4px solid #fff !important;
  border: 2px solid #DF8136 !important;
  box-sizing: border-box;
  outline: none;
  transition: width 0.4s ease-in-out, padding 0.2s;
  // transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out, padding 0.2s;
  transition-delay: 0.4s;
  color: #000000;
  // color: #DF8136 !important
  font-size: 20px;

  .btn::before {
    content: "";
    width: 2px;
    height: 18px;
    // background: white;
    background: #DF8136;
    position: absolute;
    transform: rotate(-45deg);
    bottom: -12px;
    right: -2px;
    transition: 0.3s;
  }
`
