import React, { useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
// import cart from '../../img/cart-white.svg'
// import { Img } from '~/utils/styles'
import AnimatedSearchInput from './AnimatedSearchInput'
import logo from '../../img/click-collect.png'

// const IOSWrap = styled.div`
//   padding-top: env(safe-area-inset-top);
//   padding-left: env(safe-area-inset-left);
//   padding-right: env(safe-area-inset-right);
//   padding-bottom: env(safe-area-inset-bottom);
// `

const ProductSearch = props => {
  const data = useStaticQuery(
    graphql`
      query{
        allMarkdownRemark(filter: {frontmatter: {active: {eq: "ja"}}}) {
          edges {
            node {
              frontmatter {
                title
                description
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  )

  // const { data } = props
  const allPosts = data.allMarkdownRemark.edges

  const emptyQuery = ''

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery
  })

  const handleInputChange = event => {
    // console.log(event.target.value)
    const query = event.target.value
    // const { data } = props

    const posts = data.allMarkdownRemark.edges || []

    const filteredData = posts.filter(post => {
      const { description, title, price } = post.node.frontmatter
      return (
        // description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase())
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : filteredData
  // const posts = hasSearchResults ? filteredData : allPosts

  return (
    <Panel>
      {/*
        <Close onClick={props.closeSearchModal}>
        <FontAwesomeIcon className='has-text-primary' icon={['fa', 'times']} style={{ fontSize: '2rem', cursor: 'pointer' }} />
      </Close>
      */}
      <Content>

        <h1 className='subtitle is-2' style={{ textAlign: `center`, color: '#000' }}>Suche</h1>

        <SearchBoxWrapper>
          {/*<input
            className="searchInput"
            type="text"
            aria-label="Search"
            placeholder="Type to filter posts..."
            onChange={handleInputChange}
          />*/}

          <AnimatedSearchInput
            onChange={handleInputChange}
            clear={props.closeSearchModal}
            // value={value}
            // clear={clear}
          />

        </SearchBoxWrapper>

        {posts.map(({ node }) => {
          const { excerpt } = node

          const { slug } = node.fields
          const { title, description } = node.frontmatter
          return (
            <div key={slug}>
              <header className='has-text-centered'>
                <h2>
                  <Link className='has-text-primary' to={slug}>{title}</Link>
                </h2>

              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: description || excerpt,
                  }}
                />
              </section>
              <hr />
            </div>
          )
        })}
      </Content>
      <LogoWrapper>
        <div style={{ margin: '2rem auto' }}><img src={logo} style={{ position: 'absolute', bottom: '-30px', right: '-20px', display: 'block', width: '160px', margin: '0 auto', opacity: '0.3', zIndex: '20' }} /></div>
      </LogoWrapper>
    </Panel>
  )
}

export default ProductSearch

// const Zoom = styled.div`
//   overflow: hidden;
//   height: 200px;
// `

// const Image = styled(Img)`
//   max-width: 100%;
//   height: 200px;
//   margin-left: 0;
//   margin-right: 0;
//   margin-top: 0;
//   padding-bottom: 0;
//   padding-left: 0;
//   padding-right: 0;
//   padding-top: 0;
//   margin-bottom: 1.45rem;
//   box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
//   transition: transform .5s ease;
//   &:hover{
//     transform: scale(1.1);
//   }
// `

// const Close = styled.span`
//   position: absolute;
//   z-index: 3000;
//   right: 10px;
//   top: 10px;
//   font-size: 16px;
//   cursor: pointer;
// `

const Panel = styled.div`
  // position: absolute;
  position: fixed;
  top: 0;
  z-index: 20000;
  height: 100%;
  background: #fff;
  left: 0;
  right: 0;
  padding: 20px;
  // display: block;
`

const Content = styled.div`
  // height: 400px;
  height: 100%;
  position: relative;
  margin-top: 20%;
  overflow-y: scroll;
  padding: 10px;
  overflow-x: hidden;
`

const SearchBoxWrapper = styled.div`
  margin-bottom: 10rem;
`

const LogoWrapper = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  // margin: 0 auto;
  overflow: hidden;
  height: 180px;
  z-index: 10;
  // display: flex;
  // align-items: center;
  // justify-content: center;
`
