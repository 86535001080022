import React, { useState } from 'react'
import styled from 'styled-components'
// import './animatedSearchInput.css'

const SearchInput = (props) => {
  const [active, setActive] = useState(false)
  // const [close, setClose] = useState(false)

  // const { onChange, value, label, placeholder } = props
  const { onChange, value, clear } = props

  setTimeout(function () {
    setActive(true)
  }, 500)

  function toggleActive () {
    // const currentState = active
    setActive(true)
    // setClose(!close)
  }

  return (
    <SearchWrapper>
      <div className="middle">
        <div className="search-box">
          <input
            onChange={onChange}
            value={value}
            // placeholder={placeholder}
            ref={input => setTimeout(function () {
              input && input.focus()
          }, 1000)}
            autoComplete="on"
            onClick={toggleActive}
            clear={clear}
            typeName="text"
            className={`search-input ${active ? `inclicked` : null}`}
          />
          <button onClick={clear} type="button" className={`btn ${active ? `close` : null}`}></button>
        </div>
      </div>
    </SearchWrapper>
  )
}

export default SearchInput

const SearchWrapper = styled.div`
  position: relative;
  margin: 3rem auto;
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .search-input {
    width: 60px;
    height: 60px;
    background: none;
    border-radius: 50% !important;
    // border: 4px solid #fff !important;
    border: 4px solid #DF8136 !important;
    box-sizing: border-box;
    outline: none;
    transition: width 0.4s ease-in-out, padding 0.2s;
    // transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out, padding 0.2s;
    transition-delay: 0.4s;
    color: #000000;
    // color: #DF8136 !important
    font-size: 20px;
  }
  .inclicked {
    width: 290px;
    border-bottom: 1px solid grey !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-radius: 0px !important;
    padding: 0 15px;
    padding-right: 40px;
  }
  .btn {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    right: 0;
    box-sizing: border-box;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .btn::before {
    content: "";
    width: 4px;
    height: 25px;
    // background: white;
    background: #DF8136;
    position: absolute;
    transform: rotate(-45deg);
    bottom: -16px;
    right: -6px;
    transition: 0.3s;
  }

  .close::before, .close::after {
    content: "";
    /* width: 4px;
    height: 34px; */
    width: 2px;
    height: 24px;
    // background: #fff;
    background: #DF8136;
    position: absolute;
    bottom: 12px;
    right: 28px;
    transition: 0.3s;
  }
  .close::before {
    transform: rotate(-45deg);
  }
  .close::after {
    transform: rotate(45deg);
  }
`
